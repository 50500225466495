//----- Modal
.modal-backdrop {
	background: rgba(8,15,24,0.65);
}

.modal-dialog {
	max-width: 385px;
}

.modal-header {
	padding: 15px 25px;
	background: $primary-color;
	color: #fff;
    border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	border: 0;

	h4 {
		margin: 0;
		font-size: 16px;
		color: #fff;
	}
}

.modal-content {
	border-radius: 2px;
	border: 0;
}

.modal-footer {
	border-top: 0;
}

.modal-input-group {
	@extend .input-group;
	position: relative;
	
	+ .modal-input-group {
		margin-top: 20px;
	}

	.form-control,
	.select-primary {
		width: 100%;
		max-width: none;
		border-radius: 0!important;
	}

	label {
		font-size: 14px;
	}
	
}