//----- Index/Main/First/Welcome Page
.welcome-wrapper.px-50 {
	padding: 0 0px;

	@include media-breakpoint-up(md) {
		padding: 0 15px;
	}

	@media (min-width: 1400px) {
		padding: 0 20px;
	}
}

.welcome-cell {
	padding: 25px 0px;
	text-align: justify;

	h3 {
		margin: 0;
		font-size: 24px; 
		line-height: 1.5;
		color: #222C3C;
		font-weight: 500;
	}

	h4 {
		font-size: 18px;
		margin: 50px 0 0;
		font-weight: 500;
	}

	p {
		margin: 25px 0 0 0px;

		@include media-breakpoint-up(md) {
			margin-left: 0px;
		}
	}

	.table {
		margin: 50px 0 0;

		thead {
			tr {
				&:nth-child(odd) {
					background-color: #fafcff;
				}
			}
		}

		tbody tr{
			text-align: left;

			&:nth-child(odd) {
				background: transparent;
			}

			&:nth-child(even) {
				background-color: #fafcff;
			}
		}

		th {
			padding: 13px 20px;
			color: $primary-color;
			font-weight: 500;
		}

		td {
			padding: 13px 20px;
			color: #212529;
			text-align: left;

			&:last-child {
				text-align: left;
			}
		}
	}

	@media (min-width: 1400px) {
		padding: 35px 0;
	}
}

.welcome-info {
	padding: 25px 0px;

	h3 {
		margin: 0;
		padding: 17px 25px;
		background-color: #fafcff;
		font-size: 14px;
		font-weight: 500;
		color: #00539f;
		border-radius: 2px;
		background: #fafcff;
	}

	p {
		margin: 0;
		padding: 13px 25px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;

		&.word-h {
			span {
				display: block;
			}

			+ .word-h {
				padding-top: 0;
			}
		}
	}

	@include media-breakpoint-up(md) {
		padding: 35px 0;
	}
}