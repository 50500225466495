//----- Budget Planner Tasks
.budget-planner-wrapper {
  padding: 25px 10px;

  @media (min-width: 1400px) {
    padding: 35px 45px;
  }
}

.budget-cell {
  padding: 15px 0;
  text-align: justify;

  h3 {
    margin: 0 0 20px;
    font-size: 22px;
    line-height: 1.5;
    color: #222c3c;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      margin: 0 0 25px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 26px;
    }
  }

  p {
    margin: 10px 0 0;
  }

  .list-unstyled {
    margin: 10px 0 0;

    li {
      position: relative;
      padding-left: 10px;
      list-style-type: none;
      &:before {
        content: "";
        display: block;
        margin-left: -25px;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        background: url("../images/checkbox-marked-circle-outline.png")
          no-repeat center;
        background-size: contain;
      }

      + li {
        margin-top: 7px;
      }
    }

    + p {
      margin-top: 10px;

      @include media-breakpoint-up(md) {
        margin-top: 15px;
      }
    }

    @include media-breakpoint-up(md) {
      margin: 15px 0 0;
    }
  }

  ol {
    margin: 10px 0 0;
    padding-left: 20px;
    li {
      position: relative;
      padding-left: 5px;

      + li {
        margin-top: 7px;
      }
    }

    + p {
      margin-top: 10px;

      @include media-breakpoint-up(md) {
        margin-top: 15px;
      }
    }

    @include media-breakpoint-up(md) {
      margin: 15px 0 0;
    }
  }

  .btn-primary {
    margin-top: 25px;
    margin-right: auto;

    @include media-breakpoint-down(xs) {
      + .btn-primary {
        /* margin-top: 15px; */
      }
    }

    @include media-breakpoint-up(sm) {
      margin-right: 0;
    }

    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }
  }

  @include media-breakpoint-down(sm) {
    &.py-25 {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 25px 0px;
  }

  @include media-breakpoint-up(lg) {
    padding: 25px 0;
  }
}

.income-cell {
  @extend .budget-cell;

  p {
    margin: 0;

    @include media-breakpoint-up(sm) {
      margin: 8px 0;
    }
  }

  h4 {
    margin: 0 0 10px;
    font-size: 16px;
    line-height: 1.5;
    color: #222c3c;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      margin-bottom: 15px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }
  }
}

.income-cell-wrapper {
  .row + .row {
    margin-top: 25px;

    @include media-breakpoint-up(sm) {
      margin-top: 5px;
    }
  }

  + .income-cell-wrapper {
    margin-top: 35px;

    @include media-breakpoint-up(md) {
      margin-top: 35px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }
  }

  .col-md-12 {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }

  p {
    display: inline-flex;
    flex-basis: 100%;
    min-width: 160px;
    max-width: 160px;

    @include media-breakpoint-up(md) {
      min-width: 200px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 200px;
    }
  }

  .input-group-btn-plus-minus-wrapper {
    @include media-breakpoint-up(sm) {
      flex-basis: 100%;
      display: flex;
      flex-wrap: nowrap;
      margin: 0 auto;
      max-width: 368px;
      padding: 0 15px;

      > .form-control {
        width: 100%;
        max-width: none;
        /* 			@include media-breakpoint-up(sm) {
					margin: 0 5px;
					max-width: 112px;
				}
		
				@include media-breakpoint-up(md) {
					margin: 0 10px;
					border-radius: 2px!important;
					width: 180px;	
					max-width: 180px;
				}
				
				@include media-breakpoint-up(lg) {
					margin: 0 15px;		
					width: 230px;
					max-width: 230px;
				}

				@include media-breakpoint-up(xl) {
					margin: 0 15px;		
					width: 272px;
					max-width: 272px;
				} */
      }
    }
  }

  .select-primary-wrapper {
    @include media-breakpoint-up(sm) {
      flex-basis: 100%;
      width: auto;
      max-width: 260px;
    }
  }

  .select-primary {
    @include media-breakpoint-up(sm) {
      width: 100%;
      max-width: none;
    }
    /* 		@include media-breakpoint-up(sm) {
			margin-right: 0;
			max-width: 150px;
		}
	
		@include media-breakpoint-up(md) {
			max-width: 180px;
			width: 180px;
		}
	
		@include media-breakpoint-up(lg) {
			width: 230px;
			max-width: 230px;
		}

		@include media-breakpoint-up(xl) {	
			width: 272px;
			max-width: 272px;
		} */
  }
}

.summary-cell-wrapper {
  @include media-breakpoint-up(lg) {
    .col-lg-3 {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }

  .col-md-4 + .col-md-4,
  .col-md-6 + .col-md-6 {
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
  }
}

.summary-cell {
  @extend .budget-cell;

  h4 {
    margin: 0 0 15px;
    font-size: 16px;
    line-height: 1.5;
    color: #222c3c;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }
  }
}

.finalise-cell {
  @extend .summary-cell;
}

.finalise-cell-wrapper {
  @extend .income-cell-wrapper;

  p {
    min-width: auto;
    font-size: 10px;

    @include media-breakpoint-down(md) {
      max-width: 120px;
      width: 100%;
      text-align: left;

      + p {
        max-width: 60px;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
      min-width: 200px;
    }
  }

  @include media-breakpoint-down(md) {
    .col-md-12 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.login-wrapper {
  padding: 35px 15px 35px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 16, 34, 0.1);
  border-radius: 4px;
  max-width: 700px;
  margin: 0 auto;

  h1 {
    font-size: 30px;
    margin: 0;
  }

  p {
    font-size: 16px;
    margin: 15px 0 0;
  }

  form {
    margin: 0 auto;
    max-width: 450px;
  }

  .input-group-wrapper {
    margin: 40px auto 0;
    max-width: 450px;

    label {
      width: 100%;
      font-size: 14px;
      margin-bottom: 5px;
      text-align: left;
    }

    .form-control {
      border: 1px solid #999999;

      &:focus {
        border-color: $primary-color-gradient;
      }
    }

    + .input-group-wrapper,
    &.mt-20 {
      margin-top: 20px;
    }
  }

  .select-income {
    width: 100%;
    max-width: 450px;
  }

  .btn-primary {
    margin: 20px auto 0;
    width: 100%;
    max-width: 450px;
  }
}

.create-account-btn {
  display: inline-block;
  margin: 15px auto 0;
  color: #0062cc;

  &:hover {
    color: $primary-color-gradient;
  }
}
