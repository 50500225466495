@font-face {
  font-family: 'Rubik-Regular', sans-serif;
  src: url('../fonts/Rubik-Regular.eot');
  src: url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Rubik-Regular.svg#Rubik-Regular') format('svg'),
       url('../fonts/Rubik-Regular.ttf') format('truetype'),
       url('../fonts/Rubik-Regular.woff') format('woff'),
       url('../fonts/Rubik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik-Light', sans-serif;;
  src: url('../fonts/Rubik-Light.eot');
  src: url('../fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Rubik-Light.svg#Rubik-Light') format('svg'),
       url('../fonts/Rubik-Light.ttf') format('truetype'),
       url('../fonts/Rubik-Light.woff') format('woff'),
       url('../fonts/Rubik-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik-Medium', sans-serif;;
  src: url('../fonts/Rubik-Medium.eot');
  src: url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Rubik-Medium.svg#Rubik-Medium') format('svg'),
       url('../fonts/Rubik-Medium.ttf') format('truetype'),
       url('../fonts/Rubik-Medium.woff') format('woff'),
       url('../fonts/Rubik-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

