//----- Pay Now
.pay-now-wrapper {
	@extend .success-wrapper;
}

.pay-now-cell {
	padding: 30px 15px;
	background: #fff;
	border-radius: 2px;
	border: 1px solid #e3e8ee;

	@include media-breakpoint-up(lg) {
		padding: 50px 30px;
	}
}

.pay-now-cell-title {
	text-align: center;
	margin-bottom: 30px;

	h3 {
		font-weight: 500;
		font-size: 32px;
		margin: 0;
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 50px;
	}
}

.pay-now-cell-content {
	text-align: justify;

	p {
		margin-bottom: 10px;
	}

	.input-group,
	.input-group-btn-plus-minus-wrapper,
	.btn-primary,
	.checkbox {
		margin-top: 30px;
	}

	.checkbox {
		label {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}