//----- Landing Page
/* Section Hero */
.section-hero-index {
	background: #0069a2, url('../images/header-bg-hero.png') no-repeat center;
	background: -moz-linear-gradient(-30deg, #0069a2 0%, #0088cc 100%), url('../images/header-bg-hero.png') no-repeat center;
	background: -webkit-linear-gradient(-30deg, #0069a2 0%,#0088cc 100%), url('../images/header-bg-hero.png') no-repeat center;
	background: linear-gradient(120deg, #0069a2 0%,#0088cc 100%), url('../images/header-bg-hero.png') no-repeat center;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0069a2', endColorstr='#0088cc',GradientType=1 );
	background-blend-mode: multiply;
	position: relative;

/* 	.row {
		@include media-breakpoint-down(sm) {
			flex-direction: column-reverse
		}
	} */

	> .container > .row {
		padding: 50px 0;

		@include media-breakpoint-up(md) {
			padding: 80px 0 50px;
		}
	
		@include media-breakpoint-up(lg) {
			padding: 120px 0 70px;
		}
	
		@include media-breakpoint-up(xl) {
			padding: 150px 0 100px;
		}
	}


	.list-shields {
		margin-bottom: 50px;

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
	}
}

.section-hero-index-reg {
	background: #005380;

	h1 {
		margin: 0;
		color: #fff;
		text-align: center;
		font-size: 30px;
	}

	p {
		margin: 10px 0 0;
		color: #fff;
		text-align: center;
		opacity: 0.5;;
	}

	.row {
		padding: 60px 0 30px;

		@include media-breakpoint-up(md) {
			padding: 80px 0 40px;
		}
	
		@include media-breakpoint-up(lg) {
			padding: 90px 0 50px;
		}
	
		@include media-breakpoint-up(xl) {
			padding: 100px 0 60px;
		}
	}
}

.hero-form-index {
	.form-control {
		height: 50px;
		border-color: #f5f9ff;
		border-radius: 2px;
		background: #fff;
	}

	.btn-primary {
		margin: 30px auto 0;
		width: 100%;
		text-transform: uppercase;
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid #005580;
		border-radius: 2px;
	}
}

.hero-form-index-register {
	@extend .hero-form-index;
	margin-top: 50px;
}

.hero-form-input-group {
	label {
		font-size: 12px;
		color: #f2fff9;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 8px;
		display: block;

		&.text-left {
			font-size: 14px;
			text-transform: none;
		}

		+ .form-control {
			box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
			height: 40px;
			background: #fff;

			&[type='date'] {
				height: 50px;

				&::-webkit-inner-spin-button {
					height: 36px;;
				}
			}
		}

		@include media-breakpoint-up(sm) {
			font-size: 14px;
		}
	}
}

.hero-index-txt {
	margin: 30px 0 0;
	text-align: center;
	color: #fff;
	font-size: 14px;

	a {
		color: #fff;
		text-decoration: underline;
	}
}

.back-to-login {
	margin-top: 50px;
	text-align: center;

	a {
		display: inline-block;
		color: #ffffff;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		text-align: center;
		height: 40px;
		line-height: 40px;
		width: 170px;
		border-radius: 2px;
		border: 1px solid #fff;
		opacity: 0.5;
		
		&:hover,
		&:focus {
			opacity: 1;
		}
	}
}

/* Section: Options */
.section-landing-options {
	padding: 0px 0 100px; 

	@include media-breakpoint-up(sm) {
		padding: 0px 0 100px; 	
	}

	@include media-breakpoint-up(md) {
		padding: 100px 0; 
	}

	@include media-breakpoint-up(lg) {
		padding: 110px 0; 
	}
}

.landing-option {
	display: inline-block;
	transition: 0.25s all ease-in-out;

	p {
		margin: 15px auto 0;
		font-size: 16px;
		color: #111111;
		text-align: center;
		max-width: 180px;

		@include media-breakpoint-up(md) {
			margin-top: 20px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 18px;
		}
	}

	&:hover p {
		color: $primary-color;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 100px; 
	}

	@include media-breakpoint-down(xs) {
		margin-top: 100px;
	}

	&:hover {
		.landing-option-thumb {
			opacity: 0.8;
		}
	}
}

.landing-option-thumb {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	height: 80px;
	width: 80px;
	border-radius: 25px;
	background: -moz-linear-gradient(-30deg, #0069a2 0%, #0088cc 100%);
	background: -webkit-linear-gradient(-30deg, #0069a2 0%,#0088cc 100%);
	background: linear-gradient(120deg, #0069a2 0%,#0088cc 100%);
	transition: 0.25s all ease-in-out;
	opacity: 1;

	img {
		height: 38px;

		@include media-breakpoint-up(md) {
			height: 42px;
		}
	
		@include media-breakpoint-up(lg) {
			height: 48px;
		}
	}

	@include media-breakpoint-up(md) {
		height: 90px;
		width: 90px;
	}

	@include media-breakpoint-up(lg) {
		height: 100px;
		width: 100px;
	}
}
