//----- progressbar
.progress-bar-wrapper {
	width: 100%;
	position: relative;
	min-height: 100px;
	
	&.mw-1040 {
		max-width: 1040px;
	}

	&.vertical-progressbar-wrapper {
		min-height: 382px;
	}
}

.progress-bar {
	padding: 0px 0 10px;
	width: 100%;
    list-style: none;
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    background: transparent;


/*     &::after {
    content: "";
    position: absolute;
    top: 9px;
    left: 0px;
    background-image: linear-gradient(90deg, #9D6BEC 1%, #6A2DCC 100%);
    width: 100%;
    height:  4px;
    z-index: 0;
} */

    li {
    	position: absolute;
    	min-height: 20px;
    	width: 100%;

        &.stop{
            &::after {
                background: #ACB1B6;
            }

			.progress-bar-dot {
				background: #FFFFFF;
				border: 1px solid #D7DFE9;
				box-shadow: 0 4px 8px 0 rgba(11,27,93,0.15);

				&:before {
					background: #8C5FD3;
				}
			}

             ~ li {
				
				.progress-bar-dot {
					background: #FFFFFF;
					border: 1px solid #D7DFE9;
					box-shadow: 0 4px 8px 0 rgba(11,27,93,0.15);

					&:before {
						background: #8C5FD3;
					}
				}

	            &::after {
	                height: 4px;
	                background: #ACB1B6;
	            }
             }
        }
        &::after {
            content: "";
            position: absolute;
            top: 9px;
            left: 0px;
            width:100%;
            height: 4px ;
            z-index: 1;

            .vertical-progressbar-wrapper & {
	            left: 9px;
            	height: 100%;
            	width: 4px ;
            }
        }

        &:first-child {
        	.progress-bar-group {
        		text-align: left;
        		left: 0;
        		transform: translateX(0%);
        		max-width: none;

				.vertical-progressbar-wrapper & {
					left: 40px;
					transform: translateX(0%);
				}
        	}
        }

        &:last-child {
        	width: auto;
			
			.progress-bar-group {
			    left: 50%;
    			transform: translateX(-50%);

				.vertical-progressbar-wrapper & {
					left: 40px;
					transform: translateX(0%);
				}
			}

        	&:after {
        		display: none;
        	}
        }
    }

    .vertical-progressbar-wrapper & {
		width: 100%;
		height: 100%;
		min-height: 382px;

		li {
			&.stop {
				~ li {
					&::after {
						width: 4px;
						height: 100%;
					}
				}
			}
		}
    }
}

.progress-bar-dot {
	display: block;
    background: #8C5FD3;
    border-radius: 100px;
    width: 22px;
    height: 22px;
    z-index: 2;
	position: relative;
	box-shadow: 0 2px 5px 0 rgba(146,45,201,0.40);

    &:before {
    	content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		@include vendor-prefixes(transform, translate(-50%, -50%));
		height: 8px;
		width: 8px;
		border-radius: 50%;
		background: #fff;
    }
}

.progressbar-line {
    position: absolute;
    top: 9px;
    left: 0px;
    background-image: linear-gradient(90deg, #9D6BEC 1%, #6A2DCC 100%);
    width: 100%;
    height:  4px;
    z-index: 0;	

    .vertical-progressbar-wrapper & {
    	top: 0;
    	left: 9px;
	    width: 4px;
    	height:  100%;
    }
}

.progress-bar-group {
	position: absolute;
	top: 30px;
	text-align: center;
	left: 0%;
	transform: translateX(-40%);
	min-width: 80px;
	max-width: 80px;
	white-space: normal;

	@include media-breakpoint-up(md) {
		min-width: 70px;
		max-width: 70px;
	}

	@include media-breakpoint-up(lg) {
		top: 35px;
		min-width: 80px;
		max-width: 80px;
	}

	@include media-breakpoint-up(xl) {
		top: 30px;
		white-space: nowrap;
		min-width: 100px;
		max-width: 100px;		
	}

	.vertical-progressbar-wrapper & {
		top: -3px;
		left: 40px;
		text-align: left;
		max-width: none;
		transform: none;
		min-width: 85px;
	}
}

.progress-bar-group-p {
	margin-bottom: 0;
	font-size: 12px;
	line-height: 14px;
	color: #485B69;
	letter-spacing: 0;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;

	.vertical-progressbar-wrapper & {
		height: auto;
	    justify-content: flex-start;
	}
}

.progress-bar-group-date {
	margin: 10px 0 0;
	font-size: 14px;
	line-height: 17px;
	color: #222C3C;
	letter-spacing: 0;

	.vertical-progressbar-wrapper &  {
		margin-top: 5px;
		font-weight: 500;
	}
}

.progress-sm {
	height: 8px;
	border-radius: 4px;
	overflow: hidden;
}

.progress-bar-sm {
	height: 8px;
	background-image: linear-gradient(90deg, #51ECA8 4%, #30D88E 100%);
	border-radius: 4px;
	padding: 0;
}


//progressbar circle
$borderWidth: 4px;
$animationTime: 1.5s;
$size: 70px;

//Create how many steps
$howManySteps: 100; //this needs to be even. 
//for fun try using 20 and changine in the HTML the data-percentage to 15 or 85

.progress-circle {
	width: $size;
	height: $size;
	line-height: $size;
	background: none;
	margin: 0 auto;
	box-shadow: none;
	position: relative;

	&:after {
		content: "";
		width: calc(100% - 0.1875rem);
		height: calc(100% - 0.1875rem);
		border-radius: 50%;
		border: 0.1875rem solid #eee;
		position: absolute;
		top: 0.0625rem;
		left: 0.09375rem;
	}

	> span {
		width: 50%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	.progress-left {
		left: 0;
	}

	.progress-bar {
		width: 100%;
		height: 100%;
		background: none;
		border-width: $borderWidth;
		border-style: solid;
		position: absolute;
		top: 0;
		border-color: #51ECA8;
	}

	.progress-left .progress-bar {
		left: 100%;
		border-top-right-radius: ($size/2);;
		border-bottom-right-radius: ($size/2);;
		border-left: 0;
		-webkit-transform-origin: center left;
		transform-origin: center left;
		//animation: loading-2 1.5s linear forwards 1.8s;
	}

	.progress-right {
		right: 0;
		.progress-bar {
			left: -100%;
			border-top-left-radius: ($size/2);;
			border-bottom-left-radius: ($size/2);;
			border-right: 0;
			-webkit-transform-origin: center right;
			transform-origin: center right;
			//animation: loading-1 1.8s linear forwards;
		}
	}

	.progress-value {
		display: flex;
		border-radius: 50%;
		font-size: 16px;
		text-align: center;
		align-items: center;
		justify-content: center;
		height: 100%;
		font-weight: 400;
		color: #222C3C;
 	}
}

/* This for loop creates the 	necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
@for $i from 1 through $howManySteps {
	$stepName: ($i*(100 / $howManySteps));

	//animation only the left side if below 50%
	@if $i <= ($howManySteps/2) { 
		.progress-circle[data-percentage="#{$stepName}"] {
			.progress-right .progress-bar {
				 animation: loading-#{$i} $animationTime linear forwards;
			}
			.progress-left .progress-bar {animation: 0;}
		}
	}
	//animation only the right side if above 50%
	@if $i > ($howManySteps/2)  {  
		.progress-circle[data-percentage="#{$stepName}"] {
			.progress-right .progress-bar {
				animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
			}
			.progress-left .progress-bar {
		      animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
		    }
		}
	}
}

//animation
@for $i from 1 through ($howManySteps/2) { 
	$degrees: (180/($howManySteps/2));
	$degrees: ($degrees*$i);
	@keyframes loading-#{$i}{
	    0%{
	        -webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
	    }
	    100%{
	        -webkit-transform: rotate($degrees);
				transform: rotate(#{$degrees}deg);
	    }
	}
}