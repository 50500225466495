//----- Create Plan
.create-plan-wrapper {
	@extend .budget-planner-wrapper;
}

/* Instructions */
.cp-instructions-cell {
	@extend .budget-cell;
}

/* Payments */
.cp-payments-cell {
	@extend .income-cell;

	.cp-pay-amount-txt {
		margin: 25px 0 0;
	}
}

.cp-payments-cell-wrapper {
	@extend .income-cell-wrapper;

	p {
		max-width: 185px;;
		text-align: left;

		@include media-breakpoint-down(sm) {
			max-width: none;
		}

		@include media-breakpoint-up(xl) {
			min-width: 370px;
			max-width: 370px;
		}
	}

	.input-group-btn-plus-minus-wrapper {
		@include media-breakpoint-up(sm) {
			margin:  0;
		}
	}

	.input-group-btn-plus-minus-wrapper + p {
		min-width: auto;;
	}

	.radio-btns-group {
		@extend .input-group-btn-plus-minus-wrapper;
		width: auto;
		flex-basis: auto;
		align-items: center;
		margin: 0;
	}

	input[type='date'] {
		width: 100%;
		max-width: 170%;;

		@include media-breakpoint-up(lg) {
			width: 275px;
			max-width: 275px;
		}

		@include media-breakpoint-up(md) {
			width: 248px;
			max-width: 248px;
		}
	}

	.col-md-12 {
		@include media-breakpoint-down(lg) {
			display: flex;
			align-items: center;
		}

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}

/* Summary */
.cp-summary-cell {
	@extend .summary-cell;

	.cp-pay-amount-txt {
		margin: 25px 0 0;
	}
}

.cp-summary-cell-wrapper {
	@extend .summary-cell-wrapper;
}

.cp-pay-amount-txt {
	font-size: 18px;
	margin: 25px 0 0;
	color: $primary-color;
	text-align: center;
}