//----- Quick Pay
.quick-pay-wrapper {
	@extend .pay-now-wrapper;
}

.quick-pay-cell {
	@extend .pay-now-cell;
}

.quick-pay-cell-title {
	@extend .pay-now-cell-title;
}

.quick-pay-cell-content {
	@extend .pay-now-cell-content;

	.input-group,
	.input-group-btn-plus-minus-wrapper,
	.btn-primary,
	.checkbox {
		margin-top: 30px;
	}

	.checkbox {
		label {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	h4 {
		margin: 30px 0 0;
		font-size: 24px;
		color: $primary-color;
	}
}