//----- Call Back
.success-wrapper {
	padding: 25px 0px;

	@media (min-width: 1400px) {
		padding: 35px 0px 35px;
	}
}

.success-cell {
	padding: 30px 15px;
	background: #f7faf7;
	border: 1px solid #4caf50;
	border-radius: 2px;
	box-shadow: 1px 0 3px rgba(0,0,0,0.2);

	@include media-breakpoint-up(lg) {
		padding: 50px;
	}
}

.success-cell-title {
	text-align: center;
	margin-bottom: 30px;

	img {
		height: 70px;
	}

	h3 {
		font-weight: 500;
		font-size: 24px;
		margin: 15px 0 0;
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 50px;
	}
}

.success-cell-content {
	text-align: center;

	p {
		margin-bottom: 10px;
	}
}

.card-block {
	padding: 20px 15px;
	background: #e6f2e6;
	font-weight: 500;
	font-size: 36px;
	line-height: 44px;
	border-radius: 4px;

	+ p {
		margin-top: 25px;
	}

	sup {
		top: -10px;
		font-size: 65%;
	}
}

.card-block-secondary {
	@extend .card-block;
	background: #dee1de;
}

.card-block-sm {
	@extend .card-block;
	font-size: 14px;
	font-weight: 400;

	img {
		position: relative;
		top: -2px;
		margin-right: 5px;
	}
}

.card-block-success {
	margin-top: 30px;
	@extend .card-block;
	background: #4caf50;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 15px; 
	color: #fff;
	font-weight: 400;
	
	@include media-breakpoint-up(md) {
		padding: 10px 45px; 
	}
}