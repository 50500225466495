//----- Form Inputs
.form-control {
	background: #f5f9ff;
	border: 1px solid $primary-color;
	border-radius: 2px;
	resize: none;
	@include vendor-prefixes(transition, all .25s ease-in-out);

	&:focus {
		border-color: $primary-color-gradient;
		box-shadow: none;
	}
}

.input-group {
	> .form-control:not(:first-child) {
		border-top-left-radius: 4px;
    	border-bottom-left-radius: 4px;
	}
}

.input-group-btn-plus-minus-wrapper {
	@extend .input-group;

	margin: 12px auto;
	
	> .form-control {

		height: 40px;
		width: 150px;
		border: 1px solid $primary-color;
		max-width: 100%;
		margin: 0;

		&:focus {
			border-color: $primary-color-gradient;
		}

		&:not(:first-child) {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;	
		}

		@include media-breakpoint-up(sm) {
			margin: 0 5px;
			max-width: 112px;
		}

		@include media-breakpoint-up(md) {
			margin: 0 10px;
			border-radius: 2px!important;
			width: 180px;	
			max-width: 180px;
		}
		
		@include media-breakpoint-up(lg) {
			margin: 0 15px;		
			width: 200px;
			max-width: 200px;
		}
	}

	@include media-breakpoint-up(sm) {
		display: inline-flex;
		justify-content: center;
		width: auto;
		margin: 5px 10px;
	}

	@include media-breakpoint-up(md) {
		margin: 0 20px;		
	}

	@include media-breakpoint-up(lg) {
		margin: 0 30px;		
	}
}

.input-group-btn-plus-minus {
	display: flex; 
	justify-content:center;
	align-items:center;

	.btn {
		height: 24px;
		width: 24px;
		min-width: 24px;
		display: flex; 
		justify-content:center;
		align-items:center;
		border-radius: 50%;
		border: none;
		background: #999999;
		color:#fff;
		padding: 0;

		&:hover {
			border-color: $primary-color-gradient;
			background-color: $primary-color-gradient;
			color: #fff;
		}

		@include media-breakpoint-down(xs) {
			height: 40px;
			border-radius: 2px;

			&.tb-right {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			&.tb-left {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
}

.w-plus-minus-100 {
	width: calc(100% -  78px);
}

/* Select */
.select-primary {
	display: inline-flex;
	margin: 0 auto;
	padding: 0 15px;
	border-radius: 2px!important;
	height: 40px;
	width: 100%;
	border: 1px solid $primary-color;
	max-width: 100%;
	appearance: none;
	-webkit-appearance: none;
	outline: none;
	background: #f5f9ff url('../images/scroll-blue.svg') no-repeat center right 5px;
	background-size: 22px;

	&:focus {
		border-color: $primary-color-gradient;
		background: #fff url('../images/scroll-blue.svg') no-repeat center right 5px;
		background-size: 22px;
	}

	&::-ms-expand {
		display: none;
	}

	@include media-breakpoint-up(sm) {
		margin-right: 0;
		max-width: 150px;
	}

	@include media-breakpoint-up(md) {
		max-width: 180px;
		width: 180px;
	}

	@include media-breakpoint-up(lg) {
		width: 200px;
		max-width: 200px;
	}
}