//----- .checkboxes
.checkbox-tag {
	position: relative;
	margin: 5px;

	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		text-indent: -9999px;

		&:checked + label {		
			background: #fff;
			border: 1px solid #fff;
			color: #202076;
		}
	}

	label {
		@include vendor-prefixes(display,flex);
		@include vendor-prefixes(justify-content ,center);
		@include vendor-prefixes(align-items ,center);
		@include vendor-prefixes(transition, all .25s ease-in-out);
		padding: 5px 15px;
		background: transparent;
		border: 1px solid #979797;
		border-radius: 14px;
		font-size: 14px;
		line-height: 17px;
		color: #FFFFFF;
		cursor: pointer;
	}
}

.checkbox {
	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		text-indent: -9999px;

		&:checked + label {
			&:before {
				background: $primary-color-gradient;;
				border-color: $primary-color-gradient;;
			}

			&:after {
				opacity: 1;
			}
		}
	}

	label {
		margin-bottom: 0;
		position: relative;
		padding: 15px 20px 15px 30px;
		@include vendor-prefixes(display,flex);
		@include vendor-prefixes(align-items ,center);
		@include vendor-prefixes(flex-direction ,row);
		@include vendor-prefixes(transition, all .25s ease-in-out);
		cursor: pointer;

		
		&:before {
			content: '';
			display: block;
			height: 16px;
			width: 16px;
			border-radius: 2px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0px;
			background: #f5f9ff;
			border: 1px solid $primary-color;
			@include vendor-prefixes(transition, all .25s ease-in-out);
		}

		&:after {
			content: '';
			background: url('../images/check-w.svg') center no-repeat;
			background-size: 12px;
			display: block;
			height: 16px;
			width: 16px;
			border-radius: 2px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0px;
			@include vendor-prefixes(transition, all .25s ease-in-out);		
			opacity: 0;	
			background-size: 12px;
		}
	}
}