/* CUSTOM RESETS
================================================== */
a, button, .btn {
	@include vendor-prefixes(transition, all .25s ease-in-out);
    cursor: pointer;
    outline: 0 none;

    &:hover,
	&:focus {
	    outline: 0 none;
    	text-decoration: none;
    	box-shadow: none;
	}
}

/* Custom Spacings */
.mt-35 {
	margin-top: 35px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-25 {
	margin-top: 25px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-15 {
	margin-top: 15px;
}

.ml-20 {
	margin-left: 20px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-5p {
	margin-left: 5px;
}

.mr-30 {
	margin-right: 30px;
}