//----- Site Navigation
.main-site-navigation {
	padding: 20px 0;
	background: #fff;
	box-shadow: 0 1px 0 0 rgba(0,0,0,0.1);

	.navbar-brand {
		padding: 0;

		img {
			height: 40px;;
		}
	}

	.navbar-light .navbar-nav {
		.nav-item {
			+ .nav-item {

				@include media-breakpoint-up(md) {
					margin-left: 40px;
				}
			}
		}

		.nav-link {
			color: #212121;
			padding: 0;

			&:hover,
			&.active {
				color: $primary-color;
			}
		}
	} 

	.navbar-toggler {
		border: 0;
		padding: 10px 0;

		@include media-breakpoint-down(sm) {
			display: flex;
		}
	}
}

//----- Dashboard Navigation
.dark-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #000;
    filter: alpha(opacity=25);
    opacity: 0.25;
    top: 0;
	left: 0;
	z-index: 1;
	
	&.hidden {
		display: none;
	}
}

.site-navigation {
	position: relative;
	padding: 15px 15px 15px 0;
	background: #FFFFFF;
	box-shadow: 0 1px 0 0 #D7DFE9;
	z-index: 1;
	box-shadow: 5px 0 10px 0 rgba(0, 16, 34, 0.1);
	z-index: 3;

	.navbar-toggler {
		margin: 0 50px 0 15px;		
		border: 0;
		padding: 0;

		img {
			width: 20px;
			display: block;
		}

		@include media-breakpoint-up(lg) {
			margin: 0 65px;		
		}
	}

	.navbar-brand {
		height: 30px;
		padding: 0;
		margin-right: 0;
		
		@include media-breakpoint-down(sm) {
			margin: 0 auto;
		}

		img {
			height: 100%;
			display: block;
		}

		@include media-breakpoint-up(md)  {
			height: 40px;
		}
	}

	.dropdown-toggle::after {
		border: 0;
		background: url('../images/dropdown arrow_big.svg') no-repeat center;
		background-size: contain;
		height: 10px;
		width: 10px;
		vertical-align: middle;
		margin-left: 5px;
	}

	@include media-breakpoint-down(sm) {
		.dropdown {
			padding-left: 11px;
		}
	}

	@include media-breakpoint-up(md) {
		padding-right: 50px;
	}

	&.fixed-nav {
		position: fixed;
		left:0;
		right: 0;
	}
}

.user-dropdown {
	img {
		height: 40px;
	}

	.fa {
		font-size: 30px;
		color: $primary-color;
		height: 40px;
		width: 40px;
		position: relative;
		left: 5px;
		top: 5px;
	}

	+ .dropdown-menu {
		margin-top: 15px;
		border: 0;
		border-radius: 0;
		border-bottom-right-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
		box-shadow: 0px 5px 10px 0 rgba(0, 16, 34, 0.1);

		p {
			margin-bottom: 0;
		}

		a {
			&:hover {
				background: transparent;
				color: $primary-color;
			}
		}
	}
}

.site-navigation-account {
	margin: 0 50px 0 0;
	font-size: 14px;
	line-height: 22px;
	color: #485B69;	

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.site-navigation-user {
	margin: 5px 10px 0 0;
	font-size: 14px;
	line-height: 22px;
	color: #000;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.aside-navigation {
	position: absolute;
	width: 150px;
	left: 0;
	top: 70px;
	bottom: 0;
	background: #fff;
	border: 0;
	padding: 25px 5px 15px;	
	z-index: 3;
	box-shadow: 0 15px 10px 0 rgba(0, 16, 34, 0.1);
	@include vendor-prefixes(transition, all .25s ease-in-out);
	overflow: hidden;
    overflow-y: auto;
	
	@include media-breakpoint-down(md) {
		box-shadow: 0 15px 10px 0 rgba(0, 16, 34, 0.1);
		position: fixed;
	}

	@include media-breakpoint-down(sm) {
		top: 66px;
		width: 120px;
	}

	&.hide-aside-nav {
		left: -170px;

		&.hide-aside-nav-temp {
			@include media-breakpoint-up(lg) {
				left: 0;
			}
		}
	}

	&.aside-fixed {
		position: fixed;
	}

	@media (min-width: 1400px) {
		padding-top: 45px;
	}
}

.aside-navigation-nav {
	width: 100%;
}

.aside-nav-item {
	margin-bottom: 20px;
	+ .aside-nav-item {
		//margin-top: 20px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.active {
		.aside-nav-link {
			color: #222C3C;

			span {
				background: $primary-color;
			}
		}
	}
}

.aside-nav-link {
	padding: 0;
	text-align: center;
	font-size: 13px;
	color: #222C3C;
	letter-spacing: 0;
	line-height: 17px;

	span {
		margin: 0 auto;
		@include vendor-prefixes(display,flex);
		@include vendor-prefixes(justify-content,center);
		@include vendor-prefixes(align-items,center);
		background: #828FA2;
		border-radius: 4px;
		height: 48px;
		width: 48px;
		margin-bottom: 10px;
		@include vendor-prefixes(transition, all .25s ease-in-out);

		img {
			width: 18px;
		}

		i {
			font-size: 18px;
			color: #fff;

			&.fa-mobile {
				font-size: 24px;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		font-size: 11px;
	}

	&:hover,
	&:focus {
		color: #222C3C;
		span {
			background: $primary-color;
		}
	}
}