//----- FAQ
.faq-wrapper {
	padding: 25px 0px;
	text-align: justify;

	h3 {
		margin: 0 0 20px;
		font-size: 22px;
		line-height: 1.5;
		color: #222C3C;
		font-weight: 500;

		@include media-breakpoint-up(md) {
			font-size: 24px;
			margin: 0 0 25px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 26px;
		}
	}

	@media (min-width: 1400px) {
		padding: 35px 0px 35px;
	}

	p {
		margin-bottom: 0;

		+ p {
			margin-top: 15px;
		}
	}
}