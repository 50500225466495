//----- Radiobuttons
.radiobutton-input-group {
	position: relative;
	
	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		text-indent: -9999px;

		&:checked + label {
			&:before {
				background: $primary-color-gradient;
				border: 1px solid $primary-color-gradient;
			}

			&:after {
				opacity: 1;
			}			
		}
	}

	label {
		position: relative;
		padding-left: 25px;
		font-size: 16px;
		line-height: 19px;
		color: #222C3C;
		cursor: pointer;
		margin-bottom: 0;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			height: 16px;
			width: 16px;
			border-radius: 50%;
			background: #f5f9ff;
			border: 1px solid $primary-color;
			@include vendor-prefixes(transform, translateY(-50%));
		}

		&:after {
			content: "";
			position: absolute;
			left: 5px;
			top: 50%;
			height: 6px;
			width: 6px;
			border-radius: 50%;
			background: #FFFFFF;
			box-shadow: 0 1px 2px 0 rgba(13,61,137,0.50);
			@include vendor-prefixes(transform, translateY(-50%));
			@include vendor-prefixes(transition, all .25s ease-in-out);
			opacity: 0;
		}
	}
}