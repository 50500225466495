//-----  Steps
.steps {
	border-bottom: 1px solid #D7DFE9; 
	/* border-radius: 0 0 8px 8px; */
	border-radius: 4px 4px 0 0 ;
	padding: 15px 0;
	overflow-y: hidden;

	.col {
		.arrow-outer {
			display: none;
		}

		&:first-child {
			.step-cell {
/* 				border-top-left-radius: 0;
				border-bottom-left-radius: 0; */
			}
		}

		&:last-child {
			.arrow-outer:not(.arrow-outer-before) {
				display: none;
			}


			.step-cell p {
				padding-left: 30px;

				@include media-breakpoint-up(xl) {
					padding-left: 0;
				}
			}
		}	

		&.selected-col {
			.arrow-outer {
				display: none;

				@media (min-width: 1400px)  {
					display: block;
				}
			}
		}
	}

	.selected-col ~ .col {
		.arrow-outer {
			display: block;
		}
	}

	@media (min-width: 768px) {
		padding: 15px ;
	}

	@media (min-width: 1400px)  {
		padding: 0;
	}
}

.steps-wrapper {
	/* min-width: 910px; */

	.slick-track.nav-tabs {
		border-bottom: 0;

		.nav-item {
			margin-bottom: 0;
		}
	}

	.slick-dots {
		list-style: none;
		padding-left: 0;
		margin: 15px auto 0;
		text-align: center;
		height: 6px;

		li {
			display: inline-block;
			margin: 0 3px;
			height: 6px;

			button {
				padding: 0;
				text-indent: -999px;
				background:  #828FA2;
				border: 0;
				height: 6px;
				width: 6px;
				border-radius: 14px;
				opacity: 0.14;
				@include vendor-prefixes(transition, all .25s ease-in-out);
			}

			&.slick-active {
				button {
					width: 12px;
					opacity: 1;
					background: #222C3C;
				}
			}
		}
	}

	.slick-slide {
		width: 200px;
	    overflow: hidden;
	}
}

.step-cell {
	position: relative;
	@include vendor-prefixes(display,flex);
	@include vendor-prefixes(flex-direction,row);
	@include vendor-prefixes(flex-wrap,wrap);
	@include vendor-prefixes(align-items,center);
	padding: 25px 15px 25px 15px;
	height: 100%;
	text-align: left;

	p,a {
		margin: 0 ;
		font-size: 14px;
		padding-left: 0px;
		color: #212529;

		@include media-breakpoint-down(sm) {
			white-space: nowrap;
		}

		@include media-breakpoint-up(xl) {
			padding-left: 0;
			margin-left: 10px;
		}

		@media (min-width: 1400px) {
			font-size: 16px;
			margin-left: 10px;
		}
	}

	img {
		position: absolute;
		left: 15px;
		height: 20px;
		
		@include media-breakpoint-up(xl) {
			height: 22px;
			position: relative;
			left: 0;
		}
	}

	&.active {
		@include vendor-prefixes(background-image, linear-gradient(270deg,  $primary-color-gradient 0%, $primary-color 85%));
		//background: $primary-color-gradient;
		color: #fff;
		border-radius: 4px;
		overflow: hidden;

		p,a {
			color: #fff;
		}

		.arrow-outer {
			@include vendor-prefixes(transform, translateY(-50%) scale(0.6));
			right: -17px;
			display: none;

			&.arrow-outer-before {
				display: none;
				right: auto;
				left: -15px;

				.arrow-inner {
					background: #fff;
					&:after {
						background: #fff;
					}
				}	

				@media (min-width: 1400px)  {
					display: block;
				}			
			}

			@media (min-width: 1400px) {
				display: block;
				right: -52px;
			}

			@include media-breakpoint-down(sm) {
				display: none !important;
			}
		}

		.arrow-inner {
			background: $primary-color-gradient;
			&:after {
				background: $primary-color-gradient;

				@media (min-width: 1400px) {
					background: $primary-color;
				}
			}

			@media (min-width: 1400px) {
				background: $primary-color;
			}
		}

		p,a {
			font-weight: 500;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 32px 32px 0;
			border-color: transparent white transparent transparent;

			@media (min-width: 1400px) {
				display: none;
			}
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 32px 32px;
			border-color: transparent transparent white transparent;

			@media (min-width: 1400px) {
				display: none;
			}
		}

		@media (min-width: 1400px) {
			border-radius: 0;
			@include vendor-prefixes(background-image, linear-gradient(90deg, $primary-color-gradient 13%, $primary-color 100%));
			overflow: visible;
		}
	}

	&:not(.active) {
		.arrow-outer.arrow-outer-before {
			display: none;
		}
	}

	&.done {
		.arrow-outer:not(.arrow-outer-before) {
			display: block;
		}
	}

	@include media-breakpoint-down(sm) {
		background: #Ffffff;
		border: 1px solid white;
		border-radius: 8px!important;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 32px 32px 0;
			border-color: transparent #F7FAFC transparent transparent;
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 32px 32px;
			border-color: transparent transparent #F7FAFC transparent;
		}
	}

	@media (min-width: 1400px) {
		@include vendor-prefixes(justify-content,center);
		padding: 25px 10px 25px 30px;	
		text-align: center;
	}
}

.arrow-outer {
    position: absolute;
    top: 50%;
    right: -30px;
    width: 70px;
    height: 140px;
    overflow: hidden;	
    @include vendor-prefixes(transform, translateY(-50%) scale(0.175));

    &.arrow-outer-before {
    	right: auto;
    	left: -30px;
    }

	@include media-breakpoint-down(sm) {
		display: none !important;
	}
}

.arrow-inner {
    @include vendor-prefixes(transform, rotate(45deg));
    background-color: #D7DFE9;
    width: 100px;
    height: 100px;
    top: 20px;
    left: -50px;
    position: relative;
    border-radius: 20px;	

    &:after {
    	content: "";
    	display: block;
	    background-color: #fff;
	    width: 100px;
	    height: 100px;
	    top: 10px;
	    left: -10px;
	    position: relative;
	    border-radius: 20px;
    }
}