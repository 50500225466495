//----- Buttons
.btn-primary {
	@extend .btn-primary;
	box-shadow: none;
	background: $primary-color;
	width: 200px;
	border-radius:4px;
	padding: 13px 5px 11px;
	display: inline-block;
	font-weight: 400;
	border: 0;

	&:not(:disabled):not(.disabled):hover,
	&:not(:disabled):not(.disabled):focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled):focus:active {
		background: $primary-color-gradient;
		box-shadow: none;
		color: #fff;
		outline: none;
	}

	@include media-breakpoint-down(xs) {
		width: 100%;
	}

	&.back {
		width: 120px;
		padding-left: 25px;
		padding-right: 25px;
		text-align: right;

		i {
			position: relative;
			top: 5px;
			float: left;
			font-size: 14px;
		}
	}

	&.next {
		width: 120px;
		padding-left: 25px;
		padding-right: 25px;
		text-align: left;

		i {
			position: relative;
			top: 5px;
			float: right;
			font-size: 14px;
		}
	}
}

.btn-secondary {
	@extend .btn-primary;
	background: $secondary-color;

	&:not(:disabled):not(.disabled):hover,
	&:not(:disabled):not(.disabled):focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled):focus:active {
		background: $secondary-color-gradient;
	}
}

/* Back to top button */
.back-to-top-button {
	display: inline-block;
	background-color: $primary-color;
	width: 36px;
	height: 36px;
	line-height: 36px;
	text-align: center;
	border-radius: 4px;
	position: fixed;
	bottom: 18px;
	right: 18px;
	opacity: 0;
	visibility: hidden;
	z-index: 1000;

	&:hover,
	&:active {
		cursor: pointer;
		background-color: $primary-color-gradient;
	}

	img {
		position: relative;
		top: -2px;
		transform: rotate(180deg);
		height: 10px;
	}

	&.show {
		opacity: 1;
		visibility: visible;
	}

}