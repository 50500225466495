//----- Transactions
.transactions-wrapper {
	padding: 25px 0px;
	text-align: justify;

	h3 {
		margin: 0 0 20px;
		font-size: 22px;
		line-height: 1.5;
		color: #222C3C;
		font-weight: 500;

		@include media-breakpoint-up(md) {
			font-size: 24px;
			margin: 0 0 25px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 26px;
		}
	}

	p {
		margin: 10px 0 0;
	}

	.list-unstyled {
		margin: 10px 0 0;

		li {
			position: relative;
			padding-left: 30px;

			&:before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				position:absolute;
				left: 0;
				top: 0;
				background: url('../images/checkbox-marked-circle-outline.png') no-repeat center;
				background-size: contain;
			}

			+ li {
				margin-top: 7px;
			}
		}

		+ p {
			margin-top: 10px;

			@include media-breakpoint-up(md) {
				margin-top: 15px;
			}
		}

		@include media-breakpoint-up(md) {
			margin: 15px 0 0;
		}
	}

	ol,
	ul {
		margin: 10px 0 0;
		padding-left: 20px;
		li {
			position: relative;
			padding-left: 5px;

			+ li {
				margin-top: 7px;
			}
		}

		+ p {
			margin-top: 10px;

			@include media-breakpoint-up(md) {
				margin-top: 15px;
			}
		}

		@include media-breakpoint-up(md) {
			margin: 15px 0 0;
		}
	}

	table {
		margin: 50px 0 0;

		thead tr:nth-child(odd) {
			background: transparent;
		}

		td {
			&:first-child {
				color: #212529;
			}

			&:last-child {
				text-align: left;
			}

			&.payment-transaction {
				color: #a40013;
			}

			&.debit-transaction {
				color: $primary-color;
			}
		}
	}


	@media (min-width: 1400px) {
		padding: 35px 0px 35px;
	}
}
