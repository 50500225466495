//----- Accordion
.accordion {}

.accordion-tabs-row {
	&.card-table-row {
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}		
	}
}

.accordion-tab {
	@include vendor-prefixes(display,flex);
	@include vendor-prefixes(justify-content ,center);
	@include vendor-prefixes(flex-direction ,column);
	padding: 12px 25px 12px 15px;
	background: $primary-color;
	font-size: 16px;
	line-height: 19px;
	color: #FFFFFF;
	font-weight: 500;
	height: 100%;
	position: relative;

	span {
		display: block;
		margin-bottom: 5px;
		opacity: 0.5;
		font-size: 12px;
		color: #FFFFFF;
		font-weight: 400;

		@include media-breakpoint-up(md) {
			margin-bottom: 10px;
		}
	}

	img {
		position: absolute;
		right: 10px;
		top: 50%;
		opacity: 0.5;
		@include vendor-prefixes(transform, translateY(-50%) rotate(180deg));
		@include vendor-prefixes(transition, all .25s ease-in-out);

		@include media-breakpoint-up(md) {
			right: 15px
		}

		@include media-breakpoint-up(lg) {
			right: 45px;
		}
	}

	&:hover {
		color: #fff;
		background: $primary-color;

		img {
			opacity: 1;
		}
	}

	&.collapsed {
		background: #f2f7fa;
		color: $primary-color;

		img {
			@include vendor-prefixes(transform, translateY(-50%) rotate(0deg));
		}

		&:hover {
			color: #fff;
			background: $primary-color;;
		}
	}

	@include media-breakpoint-up(md) {
			padding: 30px 15px;
			box-shadow: none;
	}

	@include media-breakpoint-up(lg) {
			padding: 20px 45px;
	}

	.accordion-collapse + & {
		margin-top: 3px;
	}
}

.accordion-body {
	background: #fff;
	border: 1px solid $primary-color;;
	padding: 15px 25px;

	&.accordion-body-y-40 {
		padding: 20px;

		@include media-breakpoint-up(md) {
			padding: 40px 25px;
		}
	}
}
