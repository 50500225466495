//----- Pricing-tables
.pricing-tables-wrapper {
	padding: 25px 10px;
	text-align: justify;

	h3 {
		margin: 0 0 20px;
		font-size: 22px;
		line-height: 1.5;
		color: #222C3C;
		font-weight: 500;

		@include media-breakpoint-up(md) {
			font-size: 24px;
			margin: 0 0 25px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 26px;
		}
	}

	p {
		margin: 10px 0 0;
	}

	ol {
		margin: 30px 0 0;
		padding-left: 20px;

		li {
			position: relative;
			padding-left: 5px;

			+ li {
				margin-top: 7px;
			}
		}

		+ p {
			margin-top: 10px;

			@include media-breakpoint-up(md) {
				margin-top: 15px;
			}
		}

		@include media-breakpoint-up(md) {
			margin: 40px 0 0;
		}
	}

	@media (min-width: 1400px) {
		padding: 35px 45px 35px;
	}
}


/* Pricing Tables */
.pricing-card {
	margin-top: 30px;
	border: 1px solid $primary-color;
	border-radius: 4px;
	text-align: center;
	box-shadow: 1px 0 3px rgba(0,0,0,0.2);

	&.pricing-card-secondary {
		border-color: $secondary-color;
		box-shadow: 2px 0 20px rgba(0,0,0,0.2);
	}
}

.pricing-card-title {
	padding: 15px 10px;
	background: $primary-color;

	h4 {
		font-size: 18px;
		margin: 0;
		color: #fff;
		text-transform: uppercase;
		font-weight: 500;
	}

	.pricing-card-secondary & {
		background: $secondary-color;
	}
}

.pricing-card-info {
	padding: 0 15px;

	p {
		font-size: 14px;
		margin: 0;
		padding: 14px 10px;
		border-bottom: 1px solid #fff;
		background: #f2f7fa;

		&:first-child {
			font-size: 16px;
			padding: 25px 0px;
			color: $primary-color;
			background: transparent;
			font-weight: 500;

			.pricing-card-secondary & {
				color: $secondary-color;
			}
		}
	}
}

.pricing-card-btn-wrapper {
	padding: 25px 15px;

	a {
		max-width: 100%;
		width: 100%;
	}
}