//----- Call Back
.call-back-wrapper {
	padding: 25px 0px;

	h3 {
		margin: 0 0 20px;
		font-size: 22px;
		line-height: 1.5;
		color: #222C3C;
		font-weight: 500;

		@include media-breakpoint-up(md) {
			font-size: 24px;
			margin: 0 0 25px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 26px;
		}
	}

	@media (min-width: 1400px) {
		padding: 35px 0px 35px;
	}
}

.call-back-input-group {
	@extend .input-group;
	position: relative;
	
	+ .call-back-input-group {
		margin-top: 20px;
	}

	.form-control,
	.select-primary {
		width: 100%;
		max-width: none;
		border-radius: 2px!important;
	}

	label {
		font-size: 14px;
	}
	
}

.label-or {
	margin-top: 20px;
	text-transform: uppercase;
	
	@include media-breakpoint-up(md) {
		margin-top: 0px;
		position: absolute;
		right: -30px;
		bottom: 7px;
	}
}

.selected-date {
	margin: 25px 0;
	background: #f2f7fa;
	padding: 15px 10px;
	text-align: center;
	border-radius: 2px;

	p {
		margin: 0;
		font-size: 14px;

		&.date-time {
			margin-top: 5px;
			font-size: 18px;
			color: $primary-color;
		}
	}
}

.how-it-works-title {
	background: $primary-color;
	color: #fff;
	font-size: 16px;
	margin: 0;
	padding: 16px 20px;
	position: relative;

	&:after {
		content: '';
		background: url('/assets/images/outline-info-24px.svg') no-repeat center;
		background-size: contain;
		display: block;
		height: 23px;
		width: 23px;;
		position: absolute;
		right: 15px;
		top: 14px;
	}
}

.how-it-works-wrapper {
	margin-top: 40px;
	border: 1px solid $primary-color;
	width: 100%;
	border-radius: 2px;
	text-align: justify;

	p {
		font-size: 16px;
		margin-bottom: 0;

		+ p {
			margin-top: 15px;
		}
	}

	ol {
		margin-top: 15px;
		padding-left: 20px;

		li {
			+ li {
				margin-top: 15px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		margin-top: 29px;
	}
}

.how-it-works-cell {
	padding: 20px;
}