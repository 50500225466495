// Fonts

$primary-font: "Rubik", sans-serif;
$primary-font-med: "Rubik-Medium", sans-serif;
$primary-font-light: "Rubik-Light", sans-serif;

// Colors
//To do
$primary-color: var(--primary-background); //primaryBackground
$primary-color-gradient: var(--primary-border-color); //primaryBorderColor
$secondary-color: #4caf50;
$secondary-color-gradient: #6ec071;
