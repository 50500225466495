//----- Cards
.card {
	background: #FFFFFF;
	box-shadow: 0 5px 10px 0 rgba(0,16,34,0.10);
	border-radius: 4px;
	border: 0;
	overflow: hidden;
}

.card-account-selected {
	margin-bottom: 15px;
	padding: 10px 10px;
	background: $primary-color;
    box-shadow: 0 5px 10px 0 rgba(0, 16, 34, 0.1);
    border-radius: 4px;
    border: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	

	p {
		color: #fff;
		margin: 0;
		font-size: 16px;
		font-weight: 500;

		a {
			color: #fff;
			text-decoration: none;
		}

		i {
			margin-right: 5px;
			font-size: 18px;

			&.fa-mobile {
				position: relative;
				top: 2px;
				font-size: 22px;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		padding: 12px 35px;
		flex-direction: row;
		justify-content: space-between;
	}

	@include media-breakpoint-up(md) {
		margin-bottom: 20px;
	}
}

.card-wrapper {
	border: 1px solid #E3E8EE;
	border-radius: 4px;

	&.card-wrapper-odd,
	.card-wrapper-odd {
		.card-row {
			&:nth-child(odd) {
				background: #F7FAFC;
			}	
		}	
	}

	&.card-wrapper-even,
	.card-wrapper-even {
		.card-row {
			&:nth-child(even) {
				background: #F7FAFC;
			}	
		}	
	}

	.card-y-border & {
		border-radius: 0;
	}
}

.card-body {
	padding: 0 0 40px;
	background-color: #fff;
}

.card-row {
	@include vendor-prefixes(display,flex);
	@include vendor-prefixes(flex-wrap,wrap);
	@include vendor-prefixes(flex-direction,row);


	+ .card-table-row,
	+ .card-table-wrapper-inside {
		border-top: 1px solid #E3E8EE;
	}	
}

/* // Card Body
.card-body {
	padding: 0 0 40px;
	background-color: #fff;
}

.card-in-spacing {
	@extend .card-header;
	@include vendor-prefixes(display,flex);
	@include vendor-prefixes(justify-content,center);
	@include vendor-prefixes(align-items,center);
}

.card-tabs-links {
	@extend .list-unstyled;
	padding: 0 40px;
	background: #32337D;
	@include vendor-prefixes(display,flex);
	@include vendor-prefixes(justify-content,center);

	li.nav-item {
		margin-bottom: 0;
		width: 20%;
	}

	a.nav-link {
		padding: 23px 5px 19px;
		text-align: center;
		font-size: 16px;
		line-height: 18px;
		color: rgba(255,255,255,0.50);
		background: #32337D;
		border: 0;
		border-bottom: 4px solid #32337D;;
		@include vendor-prefixes(display,flex);
		@include vendor-prefixes(justify-content,center);
		@include vendor-prefixes(align-items,center);
		height: 100%;
		width: 100%;

		&.active {
			color: #fff;
			background: #1F1F72;
			border-bottom-color: #8750DE;
		}

		&:not(.active):hover,
		&:not(.active):focus {
			color: #fff;
			background: #1F1F72;
			border-color: transparent;
		}
	}

	@include media-breakpoint-up(md) {
		padding: 0 10px;
	}

	@include media-breakpoint-up(lg) {
		padding: 0 40px;
	}

	@media (min-width: 1400px) {
		padding: 0 45px;
	}
}

.card-table {
	padding: 0 45px;

	&.card-table-y-border {
		padding: 0;
	}
}

.card-table-wrapper {
	border: 1px solid #E3E8EE;
	border-radius: 4px;

	&.card-table-wrapper-odd,
	.card-table-wrapper-odd {
		.card-table-row {
			&:nth-child(odd) {
				background: #F7FAFC;
			}	
		}	
	}

	&.card-table-wrapper-even,
	.card-table-wrapper-even {
		.card-table-row {
			&:nth-child(even) {
				background: #F7FAFC;
			}	
		}	
	}

	.card-table-y-border & {
		border-radius: 0;
	}
}

.card-table-row {
	@include vendor-prefixes(display,flex);
	@include vendor-prefixes(flex-wrap,wrap);
	@include vendor-prefixes(flex-direction,row);


	+ .card-table-row,
	+ .card-table-wrapper-inside {
		border-top: 1px solid #E3E8EE;
	}	
}

.empty-table-row {
	height: 230px;	
}

.card-border-r {
	border-right: 1px solid #E3E8EE;
}

.card-border-l {
	border-left: 1px solid #E3E8EE;
}

.card-table-cell {
	padding: 15px 45px;

	&.card-table-cell-y-25 {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	&.card-table-cell-y-20 {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.tooltip-circle {
		position: relative;
		top: -1px;
		margin-left: 15px;
	}
}	

.card-table-wrapper-txt {
	margin-bottom: 0;
	font-size: 14px;
	color: #989DA3;
	line-height: 20px;

	&.card-table-wrapper-txt-sm {
		font-size: 12px;
	}

	&.dark-txt {
		color: #222C3C;;
	}

	&.blue-txt {
		color: #0D99D1;
	}
}

.priority-img {
	position: absolute;
	height: 10px;
	width: 10px;
	left: 13px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		height: 10px;
		position: relative;
		top: -1px;
	}

	@media (min-width: 1400px) {
		left: 20px;
	}
}

.card-table-wrapper-txt-18 {
	font-size: 18px;
	color: #222C3C;
	line-height: 22px;
	margin-bottom: 0;
}

.card-table-row-head {
	text-align: center;
	.btn-report {
		margin-top: 30px;

		@include media-breakpoint-up(md) {
			margin-top: 0;
			float: right;
			position: relative;
			top: -4px;	
		}
	}	

	@include media-breakpoint-up(md) {
		text-align: left;
	}
}

.card-table-wrapper-heading {
	display: inline-block;
	font-size: 21px;
	line-height: 25px;
	color: #222C3C;	
	margin-bottom: 0;
	font-weight: 500;
}

/* Card boxes 
.card-box-wrapper {
	padding: 0 10px;
}

.card-box {
	position: relative;
	@include vendor-prefixes(display,flex);
	@include vendor-prefixes(flex-direction,row);
	@include vendor-prefixes(justify-content ,center);
	@include vendor-prefixes(align-items ,center);
	padding: 15px 0;
	border-radius: 15px;
	border: 1px solid #E3E8EE;
    box-shadow: 0 5px 10px 0 transparent;
    @include vendor-prefixes(transition, all .25s ease-in-out);

	&:hover {
		border-color: #fff;
	    box-shadow: 0 7px 15px 0 rgba(0, 16, 34, 0.15);

	    .delete-card-box svg {
	    	box-shadow: 0 3px 7px 0 rgba(251, 88, 88, 0.5);

	    	path {
			    d: path("M 22.089 11.044 C 22.089 17.144 17.144 22.089 11.044 22.089 C 4.944 22.089 0 17.144 0 11.044 C 0 4.944 4.944 0 11.044 0 C 17.144 0 22.089 4.944 22.089 11.044 Z");
			    stroke: rgb(251, 88, 88);
			    fill: rgb(251, 88, 88);    	
			    
			    + path {
			    	d: path("M 5.5226 11.044 L 16.5666 11.044");
    				stroke: rgb(255, 255, 255);
			    }	
	    	}

	    }
	}
}

.card-box-info {
	padding: 0 10px;

	@include media-breakpoint-up(md) {
		padding: 0 10px;
	}

	@include media-breakpoint-up(lg) {
		padding: 0px 10px;
	}

	@include media-breakpoint-up(xl) {
		padding:0 15px;
	}

	@media (min-width: 1400px) {
		padding:0 30px 0 35px;
	}
}

.card-box-title {
	margin: 0;
	font-size: 14px;
	color: #222C3C;
	font-weight: 400;
}

.card-box-paragraph {
	margin: 10px 0 0;
	font-size: 14px;
	color: #989DA3;
}

.card-box-max {
	font-size: 14px;
	margin: 15px 0 0;
	color: #989DA3;

	span {
		color: #222C3C;
	}
}

.card-box-progress {
	padding: 0 25px;

	@include media-breakpoint-up(lg) {
		padding: 0 30px;
	}

	@include media-breakpoint-up(xl) {
		padding: 0 40px;
	}

	@media (min-width: 1400px) {
		padding: 0 50px;
	}
}

.card-box-txt {
	padding: 0 50px 0 25px;

	p {
		margin: 0;
		font-size: 14px;
		color: #222C3C;

		+ p {
			margin-top: 10px;
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 0 50px 0 30px;
	}

	@include media-breakpoint-up(xl) {
		padding: 0 60px 0 40px;
	}

	@media (min-width: 1400px) {
		padding: 0 80px 0 50px;
	}
}

.delete-card-box {
	width: 24px;
	position: absolute;
	top: 50%;
	right: 20px;
	@include vendor-prefixes(transform, translateY(-50%));

	svg {
		width: 24px;
		box-shadow: 0 7px 15px 0 transparent;
		border-radius: 50%;
		@include vendor-prefixes(transition, all .25s ease-in-out);

		path {
			fill: transparent;
			@include vendor-prefixes(transition, all .25s ease-in-out);
		}
	}
} */