//----- Main
.body-wrapper {
  min-height: 100%;
  position: relative;
}

.body-wrapper-h {
  height: 100%;
  position: relative;
}

.body-bg-v2,
.body-bg {
  background: #e3e8ee;
}

.body-locked {
  overflow: hidden;
  position: fixed;
  height: 100%;
}

.pad-x-sm-down {
  @include media-breakpoint-down(sm) {
    padding-right: 0;
    padding-left: 0;
  }
}

.site-main {
  @include vendor-prefixes(transition, all 0.25s ease-in-out);

  @include media-breakpoint-up(lg) {
    padding-left: 150px;
  }

  &.hide-aside {
    padding-left: 0;

    &.hide-aside-temp {
      @include media-breakpoint-up(lg) {
        padding-left: 150px;
      }
    }
  }

  &.main-pt-70 {
    padding-top: 70px;
  }
}

.main-wrapper {
  padding: 10px 8px;

  @include media-breakpoint-up(md) {
    padding: 25px 20px;
  }

  @media (min-width: 1400px) {
    padding: 45px;
  }

  > .card {
    min-height: calc(100vh - 180px);

    @include media-breakpoint-up(sm) {
      min-height: calc(100vh - 160px);
    }

    @include media-breakpoint-up(md) {
      min-height: calc(100vh - 190px);
    }

    @media (min-width: 1400px) {
      min-height: calc(100vh - 230px);
    }
  }
}

.center-container-940 {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

/* Site Footer */
.main-site-footer {
  background: #444444;
  padding: 50px 0;

  /* 	ul {
		margin-bottom: 0;

		li {
			position: relative;
			color: #fff;
			font-size: 12px;
			padding-left: 22px;

			&:before {
				content: '';
				display: block;
				height: 14px;
				width: 14px;
				position: absolute;
				left: 0;
				top: 1px;
				background: url(../images/chevron-right-w.svg);
				background-size: contain;
			}

			+ li {
				margin-top: 15px;
			}
		}
	} */

  .col-md-3.mx-md-auto {
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
    }
  }

  .col-md-4 + .col-md-4 {
    @include media-breakpoint-down(xs) {
      margin-top: 50px;
    }
  }

  .lp-list-footer {
    margin: 20px 0 0;
    padding-left: 0;
    list-style: none;

    li {
      color: #fff;
      font-size: 14px;
      line-height: 22px;
      padding-left: 0;

      &:before {
        display: none;
      }

      + li {
        margin-top: 10px;
      }

      a {
        color: #fff;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
}

.footer-copyright {
  font-size: 12px;
  color: #fff;
  margin: 50px 0 0;
  text-align: center;

  @include media-breakpoint-down(sm) {
    margin-top: 30px;
  }
}

.lp-title-footer {
  margin: 0;
  color: #fff;
  font-size: 26px;
  font-weight: 400;

  &.mt-30 {
    margin-top: 50px;
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
}

.lp-paragraph-footer {
  margin: 20px 0 0;
  color: #fff;
  font-size: 14px;
  line-height: 24px;

  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }
}

.lp-social-list-footer {
  margin-top: 10px;
  margin-bottom: 0;

  li {
    display: inline-block;

    + li {
      margin-left: 5px;
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 20px;
  }
}
