/* LAYOUT
================================================== */
html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: $primary-font;
}

.img-responsive {
    display: block;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}