//----- Tables
.table-borderless {
	border: 0;
	margin-bottom: 0;

	tr:nth-child(odd) {
		background: #fafcff;
	}

	td {
		border: none;
		&:first-child {
			color: $primary-color-gradient;
		}

		&:last-child {
			text-align: right
		}

		@include media-breakpoint-down(md) {
			font-size: 12px;
			vertical-align: middle;
			text-align: left;
			padding: 12px 6px;
		}
	}

	th {
		border: none;
		font-weight: 500;
	}
}